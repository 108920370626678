.hero{
    padding: 3rem 1.5rem 3rem 1.5rem;
    margin: 0;
    background: var(--color-bg);
}



.introduction{
    color: white;
    width: 100%;
}

.introduction-text{
    /* width: 100%; */
    margin-bottom: 2rem;
    animation-name: textSlide;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
}


@keyframes textSlide{
    0%{
        transform: translateX(-25%);
        opacity: 0;
    }

    100%{
        transform: translateX(0);
        opacity: 1;
    }
}

.introduction-text > h1,h2{
    padding: 0;
}



.introduction-text > h2{
    font-weight:300;
}


@media screen and (min-width: 768px){

    .introduction-text > h1{
        font-size: 4rem;
    }
    
}

@media screen and (min-width: 1024px){
    .hero{
        padding: 5rem 10rem;
    }

    .introduction-text > h1{
        font-size: 5rem;
    }
}

@media screen and (min-width:1400px)
{
    .hero{
        padding: 6rem 19rem;
    }

    .introduction-text > h1{
        font-size: 4rem;
    }
}