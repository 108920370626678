/* .oxo{
    background-color: var(--color-bg);
    color: var(--color-primary);
    display: inline-block;
} */

.nav-color{ 
    background-color: var(--color-bg);
    color: var(--color-primary);
    /* padding: 1.5rem 8rem; */
    /* position: fixed;
    width: 100%;
    z-index: 100; */
    /* margin: 0; */

}




.oxo:hover{
    color: var(--color-white);
}

.nav-item{
    color: var(--color-primary);
    transition: var(--transition);
    display: inline-block;
    padding: 0.5rem 1rem;
}

.nav-item:hover{
    color: var(--color-white);
}

