.footer{
    background-color: var(--color-bg-variant);
    color: black;
    margin: 0;
    padding: 0.5rem 2rem;
    text-align: center;
}

.footer-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.icon{
    color: black;
    font-size: 1.5rem;
    margin: 0 1rem;
}

.icon:hover{
    color: grey;
}

.footer-text{
    font-size: 0.8rem;
    margin: 0.5rem 0 0 0;
}

.copyright{
    margin: 0;
}