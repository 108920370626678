@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

*{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    font-size: 13px;
}

:root{
    --color-bg: #1C6758;
    --color-bg-variant: #EEF2E6;
    --color-primary: #EEF2E6;
    --color-primary-variant: #000000;
    --color-white: #fff;
    --color-light: #D9D9D9;

    --transition: all 400ms ease;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}

html{
    scroll-behavior: smooth;
}


body{
    font-family: 'Poppins', sans-serif;
    /* background-color: var(--color-bg); */
    /* padding: 1.5rem 8rem; */
    color: var(--color-primary-variant);
    
}

h1{
    font-weight: 700;
    font-size: 2.5rem;
}

h2{
    font-weight: 700;
    font-size: 2rem;
}

/* section{
    margin-top: 8rem;
} */

section{
    margin: 2rem;
}

/* section > h2, section>h5{
  text-align: center;  
  color: var(--color-light);
} */

/* section > h2{
    color:var(--color-primary);
    margin-bottom: 3rem;
} */

.text-light{
    color:var(--color-light);
}

a{
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover{
    color: var(--color-white);
}

/* .btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.btn:hover{
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary {
    background: var(--color-primary);
    color: var(--color-bg);
} */

img{
    display: block;
    width: 100%;
    object-fit: cover;
}



/* MEDIA QUERIES (MEDIUM DEVICES) */
/* @media screen and (max-width: 1024px){
    .container{
        width: var(--container-width-md);
    }

    section{
        margin-top: 6rem;
    }
} */

/* MEDIA QUERIES (SMALL DEVICES) */
/* @media screen and (max-width: 768px){
    .container{
        width: var(--container-width-sm);
    }

    section{
        margin-bottom: 2rem;
    }
} */

@media screen and (min-width: 768px){
    *{
        font-size: 14px;
    }
    section{
        margin-bottom: 4rem;
    }
}

@media screen and (min-width: 1024px){
    *{
        font-size: 15px;
    }
    section{
        margin-bottom: 6rem;
    }
}

@media screen and (min-width: 1400px)
{
    *{
        font-size: 16px;
    }
    section{
        margin-bottom: 8rem;
    }
}