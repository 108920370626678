.about{
    background-color: var(--color-bg-variant);
    color: black;
    padding: 2rem;
    margin: 0;
    text-align: center;
}

.about > h4{
    font-weight:300;
    margin: 1rem 0;
}

.profile{
    padding-bottom: 1rem;
}


.profile-img{
    border-radius: 100%;
    width: 100%;
    max-width: 100px;
    margin-bottom: 1rem;
    height: auto;
    aspect-ratio: 1/1;
    opacity: 0.4;
    filter: alpha(opacity=40); /* msie */
    transition: .400s;
}

.profile-img:hover{
    opacity: 1;
    /* filter: alpha(opacity=100); msie */
}

@media screen and (min-width: 768px){
    .about{
        padding: 4rem 8rem;
    }
    .profile-img{
        max-width: 200px;
    }
}

@media screen and (min-width: 1024px){
    .about{
        padding: 5rem 10rem;
    }
}

@media screen and (min-width:1400px)
{
    .about{
        padding: 6rem 19rem;
    }
}