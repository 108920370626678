#contact{
    background: var(--color-bg-variant);
    margin: 0;
    padding: 2rem;
}

.contact{
    border: 1px solid #d3d3d3;
    border-radius: 6px;
    padding: 2rem;
    margin: 2rem 0;
}

.input-text{
    width: 100%;
    padding: 0.5rem 1rem;
    margin: 0.5rem 0;
    border: 1px solid var(--color-primary);
    border-radius: 0.4rem;
    transition: var(--transition);
}

input:focus{
    outline: none;
    border: 1px solid var(--color-primary-variant);
}

textarea{
    width: 100%;
    padding: 0.5rem 1rem;
    margin: 0.5rem 0;
    border: 1px solid var(--color-primary);
    border-radius: 0.4rem;
    transition: var(--transition);
}

.submit-btn{
    margin: 2rem 0;
}


.submit-input{
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    cursor: pointer;
    background-color: inherit;
}

@media screen and (min-width: 768px){
    #contact{
        padding: 4rem 8rem;
    }
}

@media screen and (min-width: 1024px){
    #contact{
        padding: 5rem 10rem;
    }
}

@media screen and (min-width:1400px){
    #contact{
        padding: 6rem 19rem;
    }
}